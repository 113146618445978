import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div className="flex flex-col items-center mt-12">
      <div className="font-bold">404</div>
      <div className="">Page Not Found</div>
      <Link to={"/"}>
        <div
          className="border px-2 py-1 rounded-md bg-toyota text-white mt-4"
          style={{ fontSize: 10 }}
        >
          Go Home
        </div>
      </Link>
    </div>
  );
}
