import { Button, Modal, Form, message } from "antd";
import { useEffect, useState } from "react";
import deleteDataGate from "../../../services/deleteDataGate";

export default function DeleteComment({
  isVisible,
  setVisible,
  gatepassId,
  selected,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    const uri = `api/gatepass/${gatepassId}/comments/${selected?._id}`;

    const response = await deleteDataGate({ data: {}, uri, token: "" });

    if (response?.success) {
      await refetch();
      message.success("Deleted Successfully");
    } else {
      message.error("Something went wrong");
    }

    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected, form]);

  return (
    <Modal
      open={isVisible}
      title="Delete Comment"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteComment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteComment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center justify-center my-8">
          <div>
            Are you Sure you want to
            <span className="text-toyota font-bold mx-1">Delete</span>
            Comment
          </div>
          <div className="font-bold text-lg">{selected?.title}?</div>
        </div>
      </Form>
    </Modal>
  );
}
