import { Outlet } from "react-router";
import { GoHome } from "react-icons/go";
import { VscFiles } from "react-icons/vsc";
import { FiUsers } from "react-icons/fi";
import logo from "../assets/images/logo-white.png";
import SideMenu from "../components/Sidebar/SideMenu";
import Profile from "../components/Header/Profile";
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

export default function MainLayout() {
  const { auth } = useContext(AuthContext);
  const [isVisible, setVisible] = useState(false);

  const manageaccess = ["AD"];
  const menus = [
    {
      id: 1,
      name: "Dashboard",
      path: "/dashboard",
      icon: <GoHome size={26} />,
    },
    {
      id: 2,
      name: "Gatepass",
      path: "/gatepass",
      icon: <VscFiles size={26} />,
    },
  ];

  const admin = [
    {
      id: 1,
      name: "Manage",
      path: "/manage",
      icon: <FiUsers size={26} />,
    },
  ];
  return (
    <div className="flex">
      <div className="w-24 min-h-screen bg-red-50 border-r">
        <div className="h-20 bg-toyota flex justify-center items-center border-b border-toyota-dark">
          <img src={logo} alt="" width={42} />
        </div>
        <div className="">
          {menus?.map((menu: any, index: number) => (
            <SideMenu menu={menu} key={index} />
          ))}
        </div>

        {manageaccess.includes(auth?.role) && (
          <div className="">
            {admin?.map((menu: any, index: number) => (
              <SideMenu menu={menu} key={index} />
            ))}
          </div>
        )}
      </div>
      <div className="flex-1 bg-gray-50">
        <div className="h-20 bg-white border-b flex  items-center px-2">
          <div className="flex justify-between items-center w-full">
            <div className="text-toyota font-bold">TTL GATEPASS</div>
            <div className="">
              {/* <Profile isVisible={isVisible} setVisible={setVisible} /> */}
            </div>
          </div>
          <Profile isVisible={isVisible} setVisible={setVisible} />
        </div>

        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
