import { Button, Modal, Form, Row, Col, Select, Input, Checkbox } from "antd";
import { useContext, useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { AuthContext } from "../../../../contexts/AuthContext";
import fetchDataTrace from "../../../../services/fetchDataTrace";
import postDataGate from "../../../../services/postDataGate";
import putDataGate from "../../../../services/putDataGate";
import getBase64 from "../../../../utils/getBase64";

const { Option } = Select;

export default function UpdateUser({
  isVisible,
  setVisible,
  selected,
  refetch,
}: any) {
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [swalProps, setSwalProps] = useState({});
  const [form] = Form.useForm();

  const roles = [
    { id: 1, name: "SA", desc: "Service Advisor" },
    { id: 2, name: "HBO", desc: "Head Branch Operation" },
    { id: 3, name: "LM", desc: "Line Manager" },
    { id: 4, name: "AP", desc: "Authorized Personel" },
    { id: 5, name: "FOM", desc: "Front Office Manager" },
    { id: 6, name: "AD", desc: "Super Admin" },
  ];

  const roleOptions = roles?.map((level: any, index: number) => (
    <Option value={level?.name} key={index}>
      {level?.desc}
    </Option>
  ));

  const onSubmit = async () => {
    setLoading(true);

    const { name, mobile, email, role } = await form.getFieldsValue();

    const data = {
      name,
      mobile,
      role,
      email,
      canApprove,
    };
    const uri = `api/users/${selected?._id}`;
    const response = await putDataGate({ data, uri, token: auth?._id });
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Updated",
        text: "Gatepass Updated Successfully",
        icon: "success",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Sorry",
        text: "Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update User"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={selected?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              initialValue={selected?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="role"
              label={"Designation"}
              initialValue={selected?.role}
              rules={[
                {
                  required: true,
                  message: "Please select level",
                },
              ]}
            >
              <Select placeholder="Designation">{roleOptions}</Select>
            </Form.Item>
          </Col>
        </Row>

        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Checkbox
            onChange={(e: any) => {
              setCanApprove(e.target.checked);
            }}
            defaultChecked={canApprove}
          >
            Approver
          </Checkbox>
        </Col>
      </Form>

      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
