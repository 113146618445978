import { useContext, useState } from "react";
import { Button, Modal, Form } from "antd";
import SweetAlert2 from "react-sweetalert2";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsCheck2 } from "react-icons/bs";
import TextArea from "antd/lib/input/TextArea";
import postDataGate from "../../../services/postDataGate";

export default function ApproveGatepass({
  isVisible,
  setVisible,
  refetch,
  gatepass,
}: any) {
  const { auth } = useContext(AuthContext);
  const [approval, setApproval] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [form] = Form.useForm();

  const approvals = [
    { id: 1, name: "Accept" },
    { id: -1, name: "Decline" },
  ];

  const onSubmit = async () => {
    setLoading(true);
    const { remarks } = form.getFieldsValue();
    const data = { remarks, userId: auth?._id };
    const action = approval.id === -1 ? "reject" : "approve";
    const type = approval.id === -1 ? "Rejected" : "Approved";
    const uri = `api/gatepass/${gatepass?._id}/${action}`;
    const response = await postDataGate({ data, uri, token: auth?._id });

    if (response.success) {
      setSwalProps({
        show: true,
        title: type,
        text: `Gatepass ${type} Successfully`,
        icon: "success",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Sorry",
        text: "Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Approve Gatepass"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="approveGate"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="approveGate" form={form} onFinish={onSubmit} layout="vertical">
        <div className="px-4">
          <div className="flex justify-between items-center mb-4">
            {approvals?.map((item: any, index: number) => (
              <div
                onClick={() => setApproval(item)}
                key={index}
                className="flex items-center hover:cursor-pointer py-2"
              >
                {approval?.id === item?.id ? (
                  <div className="flex justify-center items-center h-8 w-8 border bg-green-100">
                    <BsCheck2 />
                  </div>
                ) : (
                  <div className="h-8 w-8 border"></div>
                )}
                <div className="ml-2">{item?.name}</div>
              </div>
            ))}
          </div>
          {approval?.id === -1 ? (
            <Form.Item
              name="remarks"
              label={"Remarks"}
              rules={[
                {
                  required: true,
                  message: "Please enter remarks",
                },
              ]}
            >
              <TextArea placeholder="Remarks" className="w-full" />
            </Form.Item>
          ) : (
            <Form.Item
              name="remarks"
              label={"Remarks ( Optional )"}
              rules={[
                {
                  required: false,
                  message: "Please enter remarks",
                },
              ]}
            >
              <TextArea placeholder="Remarks" className="w-full" />
            </Form.Item>
          )}
        </div>
      </Form>
      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
