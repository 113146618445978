import { Button, Checkbox, DatePicker, Form, message, Table } from "antd";
import { IoIosLogIn } from "react-icons/io";
import logo from "../../assets/images/logo-white.png";
import CreateGatepass from "./components/CreateGatepass";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import fetchDataGate from "../../services/fetchDataGate";
import { colors } from "../../utils/contants";
import { pusher } from "../..";
import { Link } from "react-router-dom";
import { RiFilterOffLine } from "react-icons/ri";

const { RangePicker } = DatePicker;
export default function ReadGatepass() {
  const [data, setData] = useState<any>([]);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showRange, setShowRange] = useState(false);
  const start = moment(new Date()).format("YYYYMMDD");
  const end = moment(new Date()).format("YYYYMMDD");
  const [startDate, setStateDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const [form] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const onDateChange = (date: any) => {
    setStateDate(moment(new Date(date)).format("YYYYMMDD"));
    setEndDate(moment(new Date(date)).format("YYYYMMDD"));
    setDates({
      startDate: moment(new Date(date)).format("YYYYMMDD"),
      endDate: moment(new Date(date)).format("YYYYMMDD"),
      isRange: false,
    });

    handleFetchData({
      dates: {
        startDate: moment(new Date(date)).format("YYYYMMDD"),
        endDate: moment(new Date(date)).format("YYYYMMDD"),
        isRange: false,
      },
    });
  };

  const onRangeChange = (date: any) => {
    setStateDate(moment(new Date(date[0])).format("YYYYMMDD"));
    setEndDate(moment(new Date(date[1])).format("YYYYMMDD"));
    setDates({
      startDate: moment(new Date(date[0])).format("YYYYMMDD"),
      endDate: moment(new Date(date[1])).format("YYYYMMDD"),
      isRange: true,
    });

    handleFetchData({
      dates: {
        startDate: moment(new Date(date[0])).format("YYYYMMDD"),
        endDate: moment(new Date(date[1])).format("YYYYMMDD"),
        isRange: true,
      },
    });
  };

  const clearDate = () => {
    form.resetFields();
    const start = moment().startOf("isoWeek").format("YYYYMMDD");
    const end = moment().endOf("isoWeek").format("YYYYMMDD");
    setStateDate(start);
    setEndDate(end);
    setDates({
      startDate: moment(new Date()).format("YYYYMMDD"),
      endDate: moment(new Date()).format("YYYYMMDD"),
      isRange: false,
    });
  };

  const [pagination, setPagination] = useState(initial);

  const columns = [
    {
      title: "S/N",
      width: 10,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },

    {
      title: "Date In",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          <Moment format="DD/MM/YYYY">
            {record?.dates?.in === null ? "-" : record?.dates?.in}
          </Moment>
        </span>
      ),
    },
    {
      title: "Service Advisor",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.creator?.name === null ? "-" : record?.creator?.name}
        </span>
      ),
    },
    {
      title: "Customer Name",
      width: 150,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.customer?.name === null ? "-" : record?.customer?.name}
        </span>
      ),
    },
    {
      title: "WIP No.",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.service?.wip === null ? "-" : record?.service?.wip}
        </span>
      ),
    },
    {
      title: "Reg. No",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.registration === null ? "-" : record?.registration}
        </span>
      ),
    },
    {
      title: "Created",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.createdAt === null ? (
            "-"
          ) : (
            <>
              <Moment fromNow>{record?.createdAt}</Moment>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Authorized By",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.approver?.name ? record?.approver?.name : "-"}
        </span>
      ),
    },

    {
      title: "Status",
      width: 140,
      render: (record: any) => (
        <div className={`${colors[record?.status]}`}>
          {record?.status === null ? "-" : record?.status}
        </div>
      ),
    },
  ];

  const handleFetchData = async ({ dates }: any) => {
    setLoading(true);

    const response = await fetchDataGate({
      uri: `api/gatepass/reports/daily?start=${dates?.startDate}&end=${dates?.endDate}`,
    });
    if (response?.success) {
      setData(response?.payload);
    }
    setLoading(false);
  };

  const handlePageChange = (pagination: any) => {
    handleFetchData({
      pagination,
    });
  };

  const handleRefetch = () => {
    handleFetchData({
      pagination,
    });
  };

  useEffect(() => {
    const gate = pusher.subscribe(`gatepass`);
    gate.bind("created", function (data: any) {
      handleRefetch();
      message.success("Gatepass Created");
    });
    gate.bind("approved", function (data: any) {
      handleRefetch();
      message.success("Gatepass Approved");
    });

    gate.bind("closed", function (data: any) {
      handleRefetch();
      message.success("Gatepass Invoiced");
    });

    return () => {
      pusher.unsubscribe("gatepass");
    };
  }, []);

  useEffect(() => {
    handleFetchData({ dates });
  }, [dates]);

  return (
    <div className="-z-50">
      <div className="h-16 bg-toyota flex items-center justify-between px-4">
        <div className="flex items-center">
          <div className="">
            <img src={logo} alt="" width={52} />
          </div>
          <div className="ml-2 text-white text-xl ">GATE PASS</div>

          <div className=" text-white text-lg uppercase border-l pl-2 ml-2">
            {dates?.isRange ? (
              <div className="">
                <Moment format={"Do MMMM YYYY"}>{dates?.startDate}</Moment>
                <span className="px-2">-</span>
                <Moment format={"Do MMMM YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="">
                <Moment format={"Do MMMM YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-2 text-white pb-1">
            Toyota HQ ( Dar es salaam )
          </div>
          <Link to="/">
            <IoIosLogIn size={30} color="#FFFFFF" />
          </Link>
        </div>
      </div>
      <div className="bg-gray-50 border-b border-gray-300 p-1">
        <Form layout="inline" form={form}>
          {showRange ? (
            <div className="flex justify-center items-center">
              <Form.Item name="ranger">
                <RangePicker
                  allowClear={true}
                  onChange={onRangeChange}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>

              <Checkbox onChange={() => setShowRange(!showRange)}>
                <span className="text-gray-500">Date as Range</span>
              </Checkbox>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Form.Item name="picker">
                <DatePicker
                  onChange={onDateChange}
                  format={"DD-MM-YYYY"}
                  placeholder="Specific date"
                  className=" border-gray-300"
                />
              </Form.Item>
              <Checkbox onChange={() => setShowRange(!showRange)}>
                <span className="text-gray-500">Date as Range</span>
              </Checkbox>
            </div>
          )}
          <Button className="" onClick={() => clearDate()}>
            <RiFilterOffLine color="#6b7280" />
          </Button>
        </Form>
      </div>
      <div className="p-2">
        <div className=" bg-white rounded-md">
          <Table
            rowClassName={(record, index) => `hover:cursor-pointer`}
            columns={columns}
            dataSource={data}
            pagination={false}
            size={"small"}
            onChange={handlePageChange}
          />
        </div>
      </div>
      <CreateGatepass
        isVisible={isVisible}
        setVisible={setVisible}
        refetch={handleRefetch}
      />
    </div>
  );
}
