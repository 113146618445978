import { Button, Modal, Form, Row, Col, message, Select, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { AuthContext } from "../../../../contexts/AuthContext";
import putDataGate from "../../../../services/putDataGate";

export default function UpdateReason({
  isVisible,
  setVisible,
  selected,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);

    const { reason } = await form.getFieldsValue();

    const data = { reason };
    const uri = `api/reasons/${selected?._id}`;

    console.log(uri, ":::");
    const response = await putDataGate({ data, uri, token: auth?._id });
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Update",
        text: "Reason Update Successfully",
        icon: "success",
      });
      await refetch();
      setVisible(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
      setLoading(false);
      setVisible(false);
      setSwalProps({});
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Update Reason"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateReason"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateReason"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="reason"
              label={"Reason"}
              initialValue={selected?.reason}
              rules={[
                {
                  required: true,
                  message: "Please select level",
                },
              ]}
            >
              <Input placeholder="Reason" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
