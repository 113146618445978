export default function DocumentFooter() {
  return (
    <div className="absolute bottom-0 right-0 left-0 border-t border-black">
      <div className="flex flex-col justify-center items-center text-xs text-black py-1">
        <div className="" style={{ fontSize: 12 }}>
          Toyota Tanzania Ltd, P.O Box 9060, Dar es Salaam, Tanzania
        </div>
        <div className="" style={{ fontSize: 10 }}>
          TIN: 100-146-304, VRN: 10-006645-E
        </div>
        <div className="" style={{ fontSize: 10 }}>
          Email: customerservice@toyotatz.com, Phone Number: +255 (0)800 750134
        </div>
      </div>
    </div>
  );
}
