import {
  Button,
  Drawer,
  Form,
  Space,
  Input,
  Tooltip,
  Popover,
  message,
} from "antd";
import Moment from "react-moment";
import moment from "moment";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";
import postDataMail from "../../../services/postDataMail";

const { Search } = Input;

export default function GatepassPanel({
  isVisible,
  setVisible,
  title,
  stats,
  dates,
}: any) {
  const [search, setSearch] = useState("");
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [form] = Form.useForm();

  const [mailForm] = Form.useForm();

  const sendMail = async (values: any) => {
    if (stats?.data?.length === 0) {
      message.error("No Data Found");
      return;
    }
    setMailLoading(true);
    const dt = stats?.data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        "Date In":
          item?.dates?.in === null
            ? "-"
            : moment(item?.dates?.in).format("DD/MM/YYYY"),
        "Service Advisor":
          item?.creator?.name === null ? "-" : item?.creator?.name,
        "Customer Name":
          item?.customer?.name === null ? "-" : item?.customer?.name,
        "WIP No.": item?.service?.wip === null ? "-" : item?.service?.wip,
        "Vehicle Registration":
          item?.registration === null ? "-" : item?.registration,
        Ageing:
          item?.dates?.in === null
            ? "-"
            : moment(item?.dates?.in).format("DD/MM/YYYY"),
        "Created at": moment(item?.createdAt).format("DD/MM/YYYY"),
        "Forwarded To": item?.approver?.name ? item?.approver?.name : "-",
        "Authorized By": item?.flags?.approved ? item?.approver?.name : "-",
        Status: item?.status === null ? "-" : item?.status,
      };
    });

    const raw = getExportData(dt);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    mailForm.resetFields();
    toggleMessageModal(false);
  };

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };
  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">{title} :</span>
          {dates?.isRange ? (
            <div className="text-gray-500">
              <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
              <span className="px-2">-</span>
              <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
            </div>
          ) : (
            <div className="text-gray-500">
              <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
            </div>
          )}
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(false)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(false)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4 bg-white">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by registration"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex items-center">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    form={mailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" danger htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="bottom"
              open={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={20} />
                </button>
              </Tooltip>
            </Popover>
            <button
              className="border bg-white px-4 ml-2 py-1"
              onClick={() =>
                ExportToExcel(
                  stats?.data?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      "Date In":
                        item?.dates?.in === null
                          ? "-"
                          : moment(item?.dates?.in).format("DD/MM/YYYY"),
                      "Service Advisor":
                        item?.creator?.name === null
                          ? "-"
                          : item?.creator?.name,
                      "Customer Name":
                        item?.customer?.name === null
                          ? "-"
                          : item?.customer?.name,
                      "WIP No.":
                        item?.service?.wip === null ? "-" : item?.service?.wip,
                      "Vehicle Registration":
                        item?.registration === null ? "-" : item?.registration,
                      Ageing:
                        item?.dates?.in === null
                          ? "-"
                          : moment(item?.dates?.in).format("DD/MM/YYYY"),
                      "Created at": moment(item?.createdAt).format(
                        "DD/MM/YYYY"
                      ),
                      "Forwarded To": item?.approver?.name
                        ? item?.approver?.name
                        : "-",
                      "Authorized By": item?.flags?.approved
                        ? item?.approver?.name
                        : "-",
                      Status: item?.status === null ? "-" : item?.status,
                    };
                  }),
                  "Gatepass.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-24 font-bold mr-2">Vehicle</div>
              <div className="w-24 font-bold mr-2">WIP No.</div>
              <div className="w-24 font-bold mr-4">Date in</div>
              <div className="w-56 truncate font-bold">Customer</div>
            </div>
            <div className="font-bold text-center">Status</div>
          </div>
        </div>
        {stats?.data
          ?.filter((gatepass: any) => gatepass?.registration?.includes(search))
          .map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-10 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-24 mr-2 truncate">{item?.registration}</div>
                  <div className="w-24 mr-2 truncate">{item?.service?.wip}</div>
                  <div className="w-24 truncate text-left mr-4">
                    {item?.dates?.in === null ? (
                      "-"
                    ) : (
                      <span>
                        {moment(item?.dates?.in?.toString()).format(
                          "Do MMM YYYY"
                        )}
                      </span>
                    )}
                  </div>
                  <div className="w-56 truncate">
                    {item?.customer?.name === " " ? "-" : item?.customer?.name}
                  </div>
                </div>
                <div className="">{item?.status}</div>
              </div>
            </div>
          ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 py-2">
          <div className="font-bold">Total Gatepass</div>
          <div className="font-bold">
            {/* {
            data?.filter((gatepass: any) =>
                gatepass?.registration?.includes(search)
              ).length
            } */}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
