import { useState } from "react";
import { CgMoreAlt } from "react-icons/cg";
import { FiChevronRight } from "react-icons/fi";
import Moment from "react-moment";
import Spinner from "../../../components/Shared/Spinner";
import GatepassPanel from "./GatepassPanel";

export default function PendingWidget({ dates, isLoading, stats }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);

  return (
    <div
      className="col-span-12 md:col-span-6 sm:col-span-3 lg:col-span-3 border-l-4 border-b-4 rounded-lg cursor-pointer"
      style={{
        borderLeftColor: "#FFDB45",
        borderBottomColor: "#FFDB45",
        backgroundColor: "#fef9c3",
      }}
    >
      <div className="flex flex-col" onClick={() => setVisible(true)}>
        <div className="flex items-center px-4 pt-4">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center h-12 w-12 rounded-full bg-gray-100">
              <div
                className="flex justify-center items-center h-10 w-10 rounded-full"
                style={{ backgroundColor: "#FFDB45" }}
              >
                <CgMoreAlt color="#FFFFFF" size={30} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-2">
            <div className="text-gray-800">
              <div className="text-xl">Pending</div>
              {dates?.isRange ? (
                <div className="text-gray-500">
                  <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                  <span className="px-2">-</span>
                  <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
                </div>
              ) : (
                <div className="text-gray-500">
                  <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                </div>
              )}
            </div>
            <div className="font-bold text-3xl">
              {isLoading ? <Spinner /> : stats?.count}
            </div>
          </div>
        </div>
        <div className="px-4 pb-4 pt-2 flex items-center">
          <div className="">Show details</div>
          <div className="ml-2">
            <FiChevronRight />
          </div>
        </div>
      </div>
      <GatepassPanel
        isVisible={isVisible}
        title="Pending"
        dates={dates}
        setVisible={setVisible}
        stats={stats}
      />
    </div>
  );
}
