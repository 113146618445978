import { Button } from "antd";
import {
  BsCheck2,
  BsCheck2Square,
  BsDownload,
  BsEye,
  BsEyeFill,
  BsPrinter,
} from "react-icons/bs";
import ReactToPrint from "react-to-print";
import { useContext, useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import fetchDataGate from "../../services/fetchDataGate";
import { useParams } from "react-router";
import { IoChevronBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import NoResults from "../../components/Shared/NoResults";
import DocumentTop from "./components/DocumentTop";
import DocumentRef from "./components/DocumentRef";
import DocumentKey from "./components/DocumentKey";
import ApproveGatepass from "./components/ApproveGatepass";
import AddAttachment from "./components/AddAttachment";
import formatSize from "../../utils/formatSize";
import base64ToFile from "../../utils/base64ToFile";

import pht_avatar from "../../assets/images/photo.png";
import pdf_avatar from "../../assets/images/pdf.png";
import DocumentFooter from "./components/DocumentFooter";
import { colors } from "../../utils/contants";
import { AuthContext } from "../../contexts/AuthContext";
import CloseGatepass from "./components/CloseGatepass";
import { RiDeleteBin4Line } from "react-icons/ri";
import DeleteAttachment from "./components/DeleteAttachment";
import AddComment from "./components/AddComment";
import { AiOutlineClose } from "react-icons/ai";
import Moment from "react-moment";
import DeleteComment from "./components/DeleteComment";
export default function OneGatepass() {
  const { auth } = useContext(AuthContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { id } = useParams();
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [createCommModal, setCreateCommModal] = useState<boolean>(false);
  const [deleteAttaModal, setDeleteAttaModal] = useState<boolean>(false);
  const [deleteCommModal, setDeleteCommModal] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [documentModal, setDocumentModal] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>("");
  const [gatepass, setGatepass] = useState<any>({});
  const [selectedAtta, setSelectedAtta] = useState<any>({});
  const [selectedComm, setSelectedComm] = useState<any>({});

  const handleFetchData = async () => {
    const uri = `api/gatepass/${id}`;
    const response = await fetchDataGate({ uri });
    if (response?.success) {
      setGatepass(response?.payload);
    }
  };

  const downloadBase64Data = (base64String: any, fileName: any) => {
    let file = base64ToFile(base64String, fileName);
    saveAs(file, fileName);
  };

  const jobRef = useRef(null);

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="">
      <div className="flex justify-between bg-white border-b px-2">
        <Link to={"/gatepass"} className="text-xs w-20 py-3 flex items-center">
          <IoChevronBack />
          <div className="ml-2">Back</div>
        </Link>

        <div className="flex items-center">
          <div className="mr-2">
            {auth?._id === gatepass?.creator?.user ? (
              <div className="">
                {editMode ? (
                  <div
                    onClick={() => setEditMode(false)}
                    className="bg-green-200 h-8 flex items-center rounded-lg cursor-pointer"
                  >
                    <div className="flex px-1">
                      <div className="flex items-center mx-1 text-xs">Edit</div>
                      <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                        <BsCheck2 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setEditMode(true)}
                    className="bg-gray-200 h-8 flex items-center rounded-lg cursor-pointer"
                  >
                    <div className="flex px-1">
                      <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                        <BsCheck2 />
                      </div>
                      <div className="flex items-center mx-1 text-xs">Edit</div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>

          <ReactToPrint
            trigger={() => {
              return (
                <Button
                  className="bg-white flex items-center border rounded-sm p-2 focus:outline-none hover:text-red-500 w-full text-left px-2"
                  style={{ fontSize: 12 }}
                >
                  <BsPrinter size={15} className="mr-2" />
                  <div className="">Print Gatepass</div>
                </Button>
              );
            }}
            content={() => jobRef.current}
            documentTitle={`Document`}
          />

          {auth?.role !== "SA" && (
            <div className="flex">
              {gatepass?.status === "INVOICED AND CLOSED" ? null : (
                <Button
                  className="bg-white rounded-none h-8 ml-2 flex justify-center items-center"
                  style={{ fontSize: 12 }}
                  onClick={() => setApproveModal(true)}
                >
                  <BsCheck2Square size={15} />
                  <div className="ml-1">Approve Gatepass</div>
                </Button>
              )}
              {gatepass?.status === "APPROVED" && (
                <Button
                  className="bg-green-50 rounded-none h-8 ml-2 flex justify-center items-center"
                  style={{ fontSize: 12 }}
                  onClick={() => setCloseModal(true)}
                >
                  <BsCheck2Square size={15} />
                  <div className="ml-1">Close Gatepass</div>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="p-2">
        <div className="border bg-white rounded-md">
          <div className="grid gap-x-0 grid-cols-12 bg-gray-100">
            <div className="col-span-12 sm:col-span-9 md:col-span-9 bg-white p-4">
              <div className="">
                <div className="flex justify-between items-center border-b-2 border-gray-300 pb-6 pt-3 h-12">
                  <div style={{ fontSize: 12 }}>GATE PASS DOCUMENT</div>
                  <div
                    className={`${colors[gatepass?.status]}`}
                    style={{ fontSize: 12 }}
                  >
                    {gatepass?.status}
                  </div>
                </div>
                <div className="flex flex-row bg-whitish  justify-center items-start overflow-auto">
                  <div className="bg-white flex flex-col justify-between items-center">
                    <div className="bg-grayish px-0">
                      <div className="-mt-24">
                        <div ref={jobRef}>
                          <div className="book" id="invoice-doc">
                            <div className="page">
                              <div className="subpage">
                                <div className="p-1">
                                  <DocumentTop />
                                  <DocumentRef serial={gatepass.serial} />
                                  <DocumentKey gatepass={gatepass} />
                                  <DocumentFooter />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-3 md:col-span-3 bg-white border-l p-4">
              <div className="">
                <div className="flex justify-between items-center  border-b-2 border-gray-300 pb-5 pt-2 h-12">
                  <div className="" style={{ fontSize: 12 }}>
                    ATTACHMENTS
                  </div>
                  {gatepass?.status === "INVOICED AND CLOSED" ? null : (
                    <Button
                      className="flex items-center rounded-none h-7"
                      style={{ fontSize: 12 }}
                      onClick={() => setDocumentModal(true)}
                    >
                      + Add
                    </Button>
                  )}
                </div>

                <div className="mt-4">
                  {gatepass?.attachments?.length === 0 ? (
                    <div className="my-8">
                      <NoResults title={"No Attachments"} />
                    </div>
                  ) : null}
                  {gatepass?.attachments?.map((file: any, index: number) => (
                    <div className="flex justify-between mt-2" key={index}>
                      <div className="flex">
                        <div className="mr-2">
                          <img
                            src={
                              file?.document?.filetype === "application/pdf"
                                ? pdf_avatar
                                : pht_avatar
                            }
                            alt=""
                            height={15}
                            width={35}
                          />
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="">{file?.description}</div>
                          {file?.document?.filesize ? (
                            <div className="text-xs">
                              {formatSize(file?.document?.filesize)}
                            </div>
                          ) : (
                            <div className="text-xs">-</div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        {file?.document?.filetype === "application/pdf" ? (
                          <a
                            href={`${file?.document?.url}`}
                            target="_blank"
                            className="ml-4 hover:text-green-600 text-black"
                          >
                            <BsDownload />
                          </a>
                        ) : (
                          <>
                            {preview?._id === file?._id ? (
                              <button
                                onClick={() => setPreview("")}
                                className=""
                              >
                                <BsEyeFill color="#16a34a" />
                              </button>
                            ) : (
                              <button
                                onClick={() => setPreview(file)}
                                className=""
                              >
                                <BsEye />
                              </button>
                            )}

                            <a
                              href={`${file?.document?.url}`}
                              target="_blank"
                              className="ml-4 hover:text-green-600 text-black"
                            >
                              <BsDownload />
                            </a>
                          </>
                        )}

                        {editMode && (
                          <div className="ml-2">
                            <button
                              className="flex items-center rounded-none h-5 hover:bg-red-50"
                              style={{ fontSize: 12 }}
                              onClick={() => {
                                setSelectedAtta(file);
                                setDeleteAttaModal(true);
                              }}
                            >
                              <RiDeleteBin4Line size={17} color="#FF251A" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {preview ? (
                    <div className="border p-1 mt-4">
                      {preview?.filetype === "application/pdf" ? (
                        <div className="">
                          <a href={`${preview?.document?.url}`} target="_black">
                            {" "}
                            Pdf
                          </a>
                        </div>
                      ) : (
                        <img src={`${preview?.document?.url}`} />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* COMMENTS */}
              <div className="mt-8">
                <div className="flex justify-between items-center  border-b-2 border-gray-300 pb-5 pt-2 h-12">
                  <div className="" style={{ fontSize: 12 }}>
                    COMMENTS
                  </div>
                  <Button
                    className="flex items-center rounded-none h-7"
                    style={{ fontSize: 12 }}
                    onClick={() => setCreateCommModal(!createCommModal)}
                  >
                    {createCommModal ? (
                      <div>
                        <AiOutlineClose color="red" />
                      </div>
                    ) : (
                      <div>+ Add</div>
                    )}
                  </Button>
                </div>

                <div className="mt-2">
                  {createCommModal ? null : (
                    <div className="">
                      {gatepass?.comments?.length === 0 ? (
                        <div className="my-8">
                          <NoResults title={"No Comments"} />
                        </div>
                      ) : null}
                    </div>
                  )}
                  {gatepass?.comments?.map((item: any, index: number) => (
                    <div
                      className="border-l-4 border-blue-500 p-2 mb-2"
                      key={index}
                    >
                      <div className="flex justify-between">
                        <div className="">
                          <div className="font-bold mb-1">{item?.title}</div>
                          <div className="">
                            {item?.date && (
                              <div className="flex">
                                <Moment format="DD/MM/YYYY">
                                  {item?.date}
                                </Moment>
                                <div className="">
                                  [ <Moment fromNow>{item?.date}</Moment> ]
                                </div>
                              </div>
                            )}
                          </div>
                          {item?.description && (
                            <div className="">
                              <div className="text-gray-500 italic">
                                {item?.description}
                              </div>
                            </div>
                          )}

                          <div className="flex text-gray-500 text-xs mt-2">
                            <Moment fromNow>{item?.sent}</Moment>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="">
                            {editMode && (
                              <div className="ml-2">
                                <button
                                  className="flex items-center rounded-none h-5 hover:bg-red-50"
                                  style={{ fontSize: 12 }}
                                  onClick={() => {
                                    setSelectedComm(item);
                                    setDeleteCommModal(true);
                                  }}
                                >
                                  <RiDeleteBin4Line size={17} color="#FF251A" />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="">
                  {createCommModal ? (
                    <AddComment
                      isVisible={createCommModal}
                      setVisible={setCreateCommModal}
                      gatepassId={gatepass?._id}
                      refetch={handleFetchData}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ApproveGatepass
        isVisible={approveModal}
        setVisible={setApproveModal}
        refetch={handleFetchData}
        gatepass={gatepass}
      />

      <CloseGatepass
        isVisible={closeModal}
        setVisible={setCloseModal}
        refetch={handleFetchData}
        gatepass={gatepass}
      />

      <AddAttachment
        isVisible={documentModal}
        setVisible={setDocumentModal}
        refetch={handleFetchData}
        gatepass={gatepass}
      />

      <DeleteAttachment
        isVisible={deleteAttaModal}
        setVisible={setDeleteAttaModal}
        gatepassId={gatepass?._id}
        refetch={handleFetchData}
        selected={selectedAtta}
      />

      <DeleteComment
        isVisible={deleteCommModal}
        setVisible={setDeleteCommModal}
        gatepassId={gatepass?._id}
        refetch={handleFetchData}
        selected={selectedComm}
      />

      {/* {createCommModal ? (
        <AddComment
          isVisible={createCommModal}
          setVisible={setCreateCommModal}
          gatepassId={gatepass?._id}
        />
      ) : null} */}
    </div>
  );
}
