import { Button, Modal, Form, Row, Col, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";

import { BsCheck2Circle } from "react-icons/bs";
import { AuthContext } from "../../../contexts/AuthContext";
import postDataGate from "../../../services/postDataGate";
import getBase64 from "../../../utils/getBase64";

const { Option } = Select;

export default function AddAttachment({
  isVisible,
  setVisible,
  gatepass,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);
  const [invno, setInvNo] = useState<any>("");
  const [isFound, setFound] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [remoteFile, setRemoteFile] = useState<any>({});

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isChecking, setChecking] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [isInvoice, setInvoice] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [isOther, setOther] = useState<boolean>(false);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleChangeOption = (e: any) => {
    switch (e) {
      case "Tax Invoice":
        setInvoice(true);
        setOther(false);
        break;
      case "Others":
        setInvoice(false);
        setOther(true);
        break;
      default:
        setInvoice(false);
        setOther(false);
        break;
    }

    if (e === "Tax Invoice") {
      setInvoice(true);
    } else {
      setInvoice(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    const { description, desc, invoice_no } = await form.getFieldsValue();

    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    let uri = "";
    let data = {};

    if (isInvoice) {
      uri = `api/gatepass/${gatepass?._id}/invoice`;
      data = {
        description,
        invoice: base64,
        invoice_no,
        isFound,
        remoteFile,
        filedetails: {
          filetype: type,
          filesize: size,
        },
      };
    } else {
      uri = `api/gatepass/${gatepass?._id}/document`;
      data = {
        description: isOther ? desc : description,
        document: base64,
        filedetails: {
          filetype: type,
          filesize: size,
        },
      };
    }

    const response = await postDataGate({ data, uri, token: auth?._id });
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Uploaded",
        text: "Attachment Uploaded Successfully",
        icon: "success",
      });

      await refetch();
      clearForm();
      setVisible(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Sorry",
        text: "Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
    setInvoice(false);
    setFound(false);
    setErrMessage("");
    setInvNo(null);
    setOther(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [gatepass]);

  const handleFetchDocument = async () => {
    setChecking(true);

    if (!invno) {
      setChecking(false);
      return;
    }
    const response = await fetch(
      `http://dms.toyotatz.com/dms/index.php/site/docnum/q/${invno}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    if (result?.status === "success") {
      setFound(true);
      setInvNo(null);
      setRemoteFile(result?.payload[0]);
    } else {
      setErrMessage("Invoice document not found, Please attach below");
      setFound(false);
    }
    setChecking(false);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleFetchDocument();
    }, 1000);
    return () => clearTimeout(delay);
  }, [invno]);

  return (
    <Modal
      open={isVisible}
      title="Add Attachment"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="addAttachment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addAttachment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Document Description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <Select
                placeholder="Description"
                onChange={(e) => handleChangeOption(e)}
              >
                <Option value="LPO Receipt">LPO Receipt</Option>
                <Option value="Payment Receipt">Payment Receipt</Option>
                <Option value="Tax Invoice">Tax Invoice</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {isOther && (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="desc"
                label="Specify description"
                rules={[
                  {
                    required: true,
                    message: "Please enter description",
                  },
                ]}
              >
                <Input
                  placeholder="Specify other description"
                  className="w-full"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {isInvoice && (
          <div className="">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="invoice_no"
                  label="Invoice No."
                  rules={[
                    {
                      required: true,
                      message: "Please enter invoice number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Invoice No."
                    onChange={(e: any) => {
                      setInvNo(e?.target?.value);
                      setErrMessage("");
                      setFound(false);
                    }}
                    className="w-full"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className=""></div>
          </div>
        )}
        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isChecking ? (
              <div className="">
                <div className="mb-4">Checking ...</div>
                <button
                  onClick={() => {
                    setChecking(false);
                    setFound(false);
                  }}
                  className="border px-4 py-1 rounded-sm bg-gray-200"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="">
                {isFound ? (
                  // <div className="flex flex-col justify-center items-center">
                  //   <div className="text-green-600">
                  //     Document was Found on the Server
                  //   </div>
                  //   <div className="flex">
                  //     <div className="mr-2">
                  //       <img src={pdf_avatar} alt="" height={15} width={35} />
                  //     </div>
                  //     <div className="flex flex-col justify-center">
                  //       <div className="">Filename: {remoteFile?.filename}</div>
                  //       <div className="text-xs">{formatSize(6773)}</div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div className="flex flex-col justify-center items-center">
                    <BsCheck2Circle color={"#16a34a"} size={30} />
                    <div className="text-gray-600 mt-2">Document Found</div>
                    <div className="text-gray-500">{remoteFile?.filename}</div>
                  </div>
                ) : (
                  <div className="">
                    {isSelected ? (
                      <div className="flex flex-col justify-center items-center">
                        <BsCheck2Circle color={"#16a34a"} size={30} />
                        <div className="text-gray-600 mt-2">
                          Document Selected
                        </div>
                        <div className="text-gray-500">
                          {selectedFile?.name}
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        {errMessage && (
                          <div className="flex items-center">
                            <div className="text-red-600">{errMessage}</div>
                          </div>
                        )}

                        <Form.Item
                          name="file"
                          label="Upload Document"
                          rules={[
                            {
                              required: false,
                              message: "Please select file",
                            },
                          ]}
                        >
                          <Input
                            type="file"
                            placeholder="Document"
                            onChange={changeHandler}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Form>

      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
