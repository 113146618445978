import { GATE_BASE_URL } from "../utils/urls";

const fetchDataGate = async ({ uri }: any) => {
  const response = await fetch(`${GATE_BASE_URL}/${uri}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  return result;
};

export default fetchDataGate;
