interface IDocumentRefProps {
  serial: string;
}
export default function DocumentRef({ serial }: IDocumentRefProps) {
  return (
    <div className="flex justify-start items-center mb-8">
      <div className="w-28 ">No: {serial ? serial : "-"}</div>
      <div className="font-bold ml-14">
        Service Department - Customer Vehicle
      </div>
    </div>
  );
}
