import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  message,
  Input,
  Select,
  DatePicker,
} from "antd";
import { useContext, useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";

import { AuthContext } from "../../../contexts/AuthContext";
import postDataGate from "../../../services/postDataGate";
import getBase64 from "../../../utils/getBase64";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

export default function AddComment({
  isVisible,
  setVisible,
  gatepassId,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [isOther, setOther] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [isDateRequired, setDateRequired] = useState<boolean>(false);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  // function disabledDate(value: any) {
  //   const date = new Date();
  //   const start = moment(date).startOf("month").format("YYYY-MM-DD").toString();
  //   const end = moment(date).endOf("month").format("YYYY-MM-DD").toString();
  //   return !value || value.isSame(end) || value.isSameOrBefore(start);
  // }

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current.valueOf() < Date.now();
  // }

  const handleChangeOption = (e: any) => {
    switch (e) {
      case "Expected Date of LPO":
        setDateRequired(true);
        setOther(false);
        break;
      case "Others":
        setDateRequired(false);
        setOther(true);
        break;
      default:
        setDateRequired(false);
        setOther(false);
        break;
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const { title, other_title, description, date } =
      await form.getFieldsValue();

    const uri = `api/gatepass/${gatepassId}/comments`;
    const data = {
      title: isOther ? other_title : title,
      description,
      date: date || null,
    };

    const response = await postDataGate({ data, uri, token: auth?._id });
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Sent",
        text: "Comment sent Successfully",
        icon: "success",
      });

      await refetch();
      clearForm();
      setVisible(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Sorry",
        text: "Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setDateRequired(false);
    setOther(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [gatepassId]);

  return (
    // <Modal
    //   open={isVisible}
    //   title="Add Comment"
    //   onCancel={() => setVisible(false)}
    //   footer={[
    //     <Button danger key="back" onClick={clearForm}>
    //       Clear
    //     </Button>,
    //     <Button
    //       key="submit"
    //       form="addComment"
    //       htmlType="submit"
    //       type="primary"
    //       onSubmit={onSubmit}
    //       loading={isLoading}
    //       danger
    //     >
    //       Submit
    //     </Button>,
    //   ]}
    // >
    <div className="">
      <Form
        id="addComment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Select
                placeholder="Title"
                onChange={(e) => handleChangeOption(e)}
              >
                <Option value="Expected Date of LPO">
                  Expected Date of LPO
                </Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>

          {isOther && (
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="other_title"
                label="Specify other title"
                rules={[
                  {
                    required: true,
                    message: "Please enter other title",
                  },
                ]}
              >
                <Input type="text" placeholder="Specify other title" />
              </Form.Item>
            </Col>
          )}
        </Row>

        {isDateRequired && (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please enter date",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  className="w-full"
                  disabledDate={(current) => {
                    let customDate = moment().format("DD-MM-YYYY");
                    return (
                      current && current < moment(customDate, "DD-MM-YYYY")
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" className="w-full" />
            </Form.Item>
          </Col>
        </Row>
        <div className="">
          <Form.Item>
            <Button danger type="primary" htmlType="submit">
              Send
            </Button>
            <Button
              danger
              htmlType="button"
              className="mx-2"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>

      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </div>
    // </Modal>
  );
}
