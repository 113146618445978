import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import CreateReason from "./components/CreateReason";
import postDataMail from "../../../services/postDataMail";
import fetchDataGate from "../../../services/fetchDataGate";
import { AuthContext } from "../../../contexts/AuthContext";
import UpdateReason from "./components/UpdateReason";
import DeleteReason from "./components/DeleteReason";

export default function AllReasons() {
  const { auth } = useContext(AuthContext);
  const [reasons, setReasons] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [signModal, setSignModal] = useState<boolean>(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    limit: 10,
  });

  const [emailForm] = Form.useForm();

  const handleFetchReasons = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;

    const uri = `api/reasons?skip=${skip}&limit=${limit}`;
    const response = await fetchDataGate({ uri, token: auth?._id });
    if (response?.success) {
      setReasons(response?.payload);
    }
    setLoading(false);
  };

  const handleRefetch = () => {
    handleFetchReasons({
      pagination,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.reason ? record?.reason : "-"}
          </span>
        );
      },
    },

    {
      title: "Action",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  const fetchAllUsers = async () => {
    const response = await fetchDataGate({
      uri: "api/v1/users",
      token: auth?._id,
    });
    if (response?.success) {
      ExportToExcel(
        response?.payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name === null ? "-" : item?.name,
            Email: item?.email === null ? "-" : item?.email,
            Mobile: item?.mobile === null ? "-" : item?.mobile,
            Website: item?.website === null ? "-" : item?.website,
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Users.xlsx"
      );
    }
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllUsers();
    setFetching(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = reasons?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Address: item?.address === null ? "-" : item?.address,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Active: item?.isActive ? "Yes" : "No",
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  useEffect(() => {
    handleFetchReasons({ pagination });
  }, []);

  return (
    <Manage
      title={"Reasons"}
      subtitle={"All"}
      description={"List of all reasons"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            visible={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  reasons?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                      Address: item?.address === null ? "-" : item?.address,
                      Email: item?.email === null ? "-" : item?.email,
                      Mobile: item?.mobile === null ? "-" : item?.mobile,
                      Active: item?.isActive ? "Yes" : "No",
                    };
                  }),
                  "Users.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
          <CreateReason
            isVisible={createModal}
            setVisible={setCreateModal}
            refetch={handleRefetch}
          />

          <UpdateReason
            isVisible={updateModal}
            setVisible={setUpdateModal}
            selected={selected}
            refetch={handleRefetch}
          />

          <DeleteReason
            isVisible={deleteModal}
            setVisible={setDeleteModal}
            selected={selected}
            refetch={handleRefetch}
          />
        </div>
      }
    >
      <>
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="middle"
                  dataSource={isLoading ? [] : reasons}
                  columns={columns}
                  // pagination={pagination}
                  scroll={{ y: 660, x: 0 }}
                  rowKey="id"
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Manage>
  );
}
