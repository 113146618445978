import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import fetchDataGate from "../../../services/fetchDataGate";

export default function WeekChart({ dates }: any) {
  const [data, setData] = useState<any>([]);
  const labelFormatter = (value: any) => {
    return value;
  };

  const handleFetchData = async () => {
    let mon = {
      name: "Monday",
      count: 0,
      data: [],
    };
    let tue = {
      name: "Tuesday",
      count: 0,
      data: [],
    };
    let wed = {
      name: "Wednesday",
      count: 0,
      data: [],
    };
    let thr = {
      name: "Thursday",
      count: 0,
      data: [],
    };
    let fri = {
      name: "Friday",
      count: 0,
      data: [],
    };
    const response = await fetchDataGate({
      uri: `api/gatepass/reports/week?start=${dates?.startDate}&end=${dates?.endDate}`,
    });
    if (response?.success) {
      response?.payload?.forEach((item: any) => {
        switch (item?._id) {
          case "1":
            mon.count = item?.gatepasses.length;
            mon.data = item?.gatepasses;
            break;
          case "2":
            tue.count = item?.gatepasses.length;
            tue.data = item?.gatepasses;
            break;
          case "3":
            wed.count = item?.gatepasses.length;
            wed.data = item?.gatepasses;
            break;
          case "4":
            thr.count = item?.gatepasses.length;
            thr.data = item?.gatepasses;
            break;
          case "5":
            fri.count = item?.gatepasses.length;
            fri.data = item?.gatepasses;
            break;
          default:
        }
      });
    }
    setData([mon, tue, wed, thr, fri]);
  };

  useEffect(() => {
    handleFetchData();
  }, [dates]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 25, right: 30, left: 0, bottom: 15 }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }}>
          <Label offset={0} position="insideBottom" />
        </XAxis>
        <YAxis />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="count" fill="#5766ED" background={{ fill: "#eee" }}>
          <LabelList
            dataKey="count"
            position="top"
            formatter={labelFormatter}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
