import { useEffect, useState } from "react";
import { AiOutlineBarChart } from "react-icons/ai";
import Moment from "react-moment";
import Header from "./components/Header";
import MainChart from "./components/MainChart";
import ReasonChart from "./components/ReasonChart";
import WeekChart from "./components/WeekChart";
import PendingWidget from "./components/PendingWidget";
import NotApprovedWidget from "./components/NotApprovedWidget";
import ApprovedWidget from "./components/ApprovedWidget";
import InvoicedWidget from "./components/InvoicedWidget";
import fetchDataGate from "../../services/fetchDataGate";
import moment from "moment";

export default function Dashboard() {
  const [isLoading, setLoading] = useState<boolean>(false);
  
  const [pending, setPending] = useState<any>({
    name: "Pending",
    count: 0,
    data: [],
  });

  const [notApproved, setNotApproved] = useState<any>({
    name: "Not Approved",
    count: 0,
    data: [],
  });

  const [approved, setApproved] = useState<any>({
    name: "Approved",
    count: 0,
    data: [],
  });
  const [invoiced, setInvoiced] = useState<any>({
    name: "Invoiced",
    count: 0,
    data: [],
  });
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const handleFetchStatus = async (dates: any) => {
    setLoading(true);
    const response = await fetchDataGate({
      uri: `api/gatepass/reports/status?start=${dates?.startDate}&end=${dates?.endDate}`,
    });
    if (response?.success) {
      const pending = response.payload.filter(
        (item: any) => item?._id === "PENDING"
      );

      setPending({
        name: "Pending",
        count: pending[0]?.gatepasses?.length || 0,
        data: pending[0]?.gatepasses || [],
      });

      const notapproved = response.payload.filter(
        (item: any) => item?._id === "NOT APPROVED"
      );

      setNotApproved({
        name: "Not Approved",
        count: notapproved[0]?.gatepasses?.length || 0,
        data: notapproved[0]?.gatepasses || [],
      });

      const approved = response.payload.filter(
        (item: any) => item?._id === "APPROVED"
      );

      setApproved({
        name: "Approved",
        count: approved[0]?.gatepasses?.length || 0,
        data: approved[0]?.gatepasses || [],
      });

      const invoiced = response.payload.filter(
        (item: any) => item?._id === "INVOICED AND CLOSED"
      );

      setInvoiced({
        name: "Invoiced",
        count: invoiced[0]?.gatepasses?.length || 0,
        data: invoiced[0]?.gatepasses || [],
      });
    }

    setLoading(false);
  };


  const handleDateChange = async (data: any) => {
    setDates(data);
    handleFetchStatus(dates);
  };

  useEffect(() => {
    handleFetchStatus(dates);
  }, [dates]);
  return (
    <div className="">
      <Header stateHandler={handleDateChange} />
      <div className="p-4 h-auto">
        <div className="grid grid-cols-12 gap-2">
          <PendingWidget dates={dates} isLoading={isLoading} stats={pending} />
          <NotApprovedWidget
            dates={dates}
            isLoading={isLoading}
            stats={notApproved}
          />
          <ApprovedWidget
            dates={dates}
            isLoading={isLoading}
            stats={approved}
          />
          <InvoicedWidget
            dates={dates}
            isLoading={isLoading}
            stats={invoiced}
          />
        </div>
        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Weekly Metrics</div>
            <div className="h-80 w-full">
              <WeekChart dates={dates} />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">
              <div className="flex items-center text-gray-800">
                <div className="text-xl mr-2">Gatepass by Reason : </div>
                {dates?.isRange ? (
                  <div className="text-gray-400">
                    <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                    <span className="px-2">-</span>
                    <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
                  </div>
                ) : (
                  <div className="text-gray-400">
                    <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                  </div>
                )}
              </div>
            </div>
            <div className="h-80 w-full">
              <ReasonChart dates={dates} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Monthly Metrics</div>
            <div className="h-auto w-full">
              <MainChart />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Other Metrics</div>
            <div className="h-auto w-full">
              <div className="flex flex-col items-center mt-24">
                <AiOutlineBarChart color={"#E2E2E2"} size={50} />
                <div className="text-gray-300">Other Metrics Here</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
