import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Page404 from "./pages/errors/Page404";
import Dashboard from "./pages/dashboard";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import AllUsers from "./pages/manage/users/AllUsers";
import Main from "./pages/manage/Main";
import AuthLayout from "./layouts/AuthLayout";
import Signin from "./pages/auth/Signin";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Denied from "./pages/auth/Denied";
import AllGatepass from "./pages/gatepass/AllGatepass";
import OneGatepass from "./pages/gatepass/OneGatepass";
import AllReasons from "./pages/manage/reasons/AllReasons";
import HomeLayout from "./layouts/HomeLayout";
import ReadGatepass from "./pages/gatepass/ReadGatepass";
import React from "react";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/denied" element={<Denied />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gatepass"
            element={
              <ProtectedRoute>
                <AllGatepass />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gatepass/:id"
            element={
              <ProtectedRoute>
                <OneGatepass />
              </ProtectedRoute>
            }
          />
          <Route path="/manage" element={<Main />} />
          <Route
            path="/manage/users"
            element={
              <ProtectedRoute>
                <AllUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage/reasons"
            element={
              <ProtectedRoute>
                <AllReasons />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path="/welcome" element={<ReadGatepass />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </AuthProvider>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { token } = React.useContext(AuthContext);
  let location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
