import { FiUsers } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import AdmMenu from "../../components/Sidebar/AdmMenu";

export default function Manage({
  children,
  title,
  subtitle,
  description,
  actions,
}: any) {
  const menus = [
    {
      id: 1,
      to: "/manage/users",
      name: "Users",
      icon: <FiUsers />,
    },
    {
      id: 2,
      to: "/manage/reasons",
      name: "Reasons",
      icon: <HiOutlineClipboardList />,
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-0 m-2 border rounded-lg">
      <div className="bg-white col-span-2 rounded-l-lg border-r">
        {menus?.map((menu: any, index: number) => (
          <AdmMenu to={`${menu?.to}`} icon={menu?.icon} key={index}>
            {menu?.name}
          </AdmMenu>
        ))}
      </div>
      <div className="bg-white col-span-10 h-full rounded-r-lg">
        <div className="border-b p-4 flex justify-between items-center h-18">
          <div className="flex justify-center items-center">
            <div className="">
              <span className="text-lg font-bold">{title} : </span>
              <span>{subtitle}</span>
              <div className="">{description}</div>
            </div>
          </div>
          {actions}
        </div>
        <div className="">{children}</div>
      </div>
    </div>
  );
}
