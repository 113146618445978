import Moment from "react-moment";

interface IDocumentRefProps {
  gatepass: any;
}
export default function DocumentKey({ gatepass }: IDocumentRefProps) {
  return (
    <div className="flex flex-col">
      <div className="flex mb-4">
        <div className="font-bold">Name:</div>
        <div className="flex-1 border-b border-gray-800 ml-2">
          {gatepass?.name}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="font-bold">Address:</div>
        <div className="flex-1 border-b border-gray-800 ml-2">
          {gatepass?.address}
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex mb-4">
          <div className="font-bold">Vehicle No:</div>
          <div className="border-b border-gray-800 ml-2 w-52">
            {gatepass?.registration}
          </div>
        </div>
        <div className="flex mb-4">
          <div className="font-bold">Date & Time:</div>
          <div className="flex-1 border-b border-gray-800 ml-2 w-52">
            <Moment format="DD/MM/YYYY">{gatepass?.dates?.approved}</Moment>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex mb-4">
          <div className="font-bold">Date in:</div>
          <div className="border-b border-gray-800 ml-2 w-60">
            <Moment format="DD/MM/YYYY">{gatepass?.dates?.in}</Moment>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="font-bold">WIP No:</div>
          <div className="flex-1 border-b border-gray-800 ml-2 w-60">
            {gatepass?.service?.wip}
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="font-bold">Reference:</div>
        <div className="flex-1 border-b border-gray-800 ml-2">
          {gatepass?.reference}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="font-bold">Reason:</div>
        <div className="flex-1 border-b border-gray-800 ml-2">
          {gatepass?.reason}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="font-bold">Service Advisor:</div>
        <div className="flex-1 border-b border-gray-800 ml-2">
          {gatepass?.creator?.name}
        </div>
      </div>

      <div className="border border-black h-12 mt-3 mb-4"></div>

      <div className="flex border-b border-black pb-2">
        <div className="w-2/4">
          <div className="font-bold">Authorized By</div>
          <div className="">
            <span className="font-bold">Name:</span>{" "}
            {gatepass?.flags?.approved ? (
              <span>{gatepass?.approver?.name}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-1/4 flex">
          <div className="font-bold mr-2">Signature:</div>
          <div className="">
            {gatepass?.flags?.approved ? (
              <img src={gatepass?.approver?.signature} alt="" width={60} />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-1/4"></div>
      </div>
    </div>
  );
}
