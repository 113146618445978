import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Popover,
  Table,
  Tooltip,
} from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import CreateGatepass from "./components/CreateGatepass";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import fetchDataGate from "../../services/fetchDataGate";
import { useNavigate } from "react-router";
import { colors } from "../../utils/contants";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../utils/exportExcel";
import postDataMail from "../../services/postDataMail";
import moment from "moment";
import { pusher } from "../..";
const { Search } = Input;
const { RangePicker } = DatePicker;
export default function AllGatepass() {
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [showRange, setShowRange] = useState(false);

  const start = moment(new Date()).format("YYYYMMDD");
  const end = moment(new Date()).format("YYYYMMDD");

  const [startDate, setStateDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };

  const [form] = Form.useForm();

  const [pagination, setPagination] = useState(initial);

  const [emailForm] = Form.useForm();

  const columns = [
    {
      title: "S/N",
      width: 10,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },

    {
      title: "Date In",
      width: 60,
      render: (record: any) => (
        <span className="text-gray-700">
          <Moment format="DD/MM/YYYY">
            {record?.dates?.in === null ? "-" : record?.dates?.in}
          </Moment>
        </span>
      ),
    },
    {
      title: "Service Advisor",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.creator?.name === null ? "-" : record?.creator?.name}
        </span>
      ),
    },
    {
      title: "Customer Name",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.customer?.name === null ? "-" : record?.customer?.name}
        </span>
      ),
    },
    {
      title: "WIP",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.service?.wip === null ? "-" : record?.service?.wip}
        </span>
      ),
    },
    {
      title: "Reg. No",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.registration === null ? "-" : record?.registration}
        </span>
      ),
    },
    {
      title: "Ageing",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">
          <Moment format="DD/MM/YYYY">
            {record?.dates?.in === null ? "-" : record?.dates?.in}
          </Moment>
        </span>
      ),
    },

    {
      title: "Created",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.createdAt === null ? (
            "-"
          ) : (
            <>
              <Moment fromNow>{record?.createdAt}</Moment>
            </>
          )}
        </span>
      ),
    },

    {
      title: "Reason",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">{record?.reason || "-"}</span>
      ),
    },

    {
      title: "Forwarded To",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.approver?.name ? record?.approver?.name : "-"}
        </span>
      ),
    },
    {
      title: "Authorized By",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.flags?.approved ? (
            <span>{record?.approver?.name ? record?.approver?.name : "-"}</span>
          ) : (
            ""
          )}
        </span>
      ),
    },

    {
      title: "Status",
      width: 140,
      render: (record: any) => (
        <div className={`${colors[record?.status]}`}>
          {record?.status === null ? "-" : record?.status}
        </div>
      ),
    },
  ];

  const handleFetchData = async ({ pagination }: any) => {
    setLoading(true);

    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const response = await fetchDataGate({
      uri: `api/gatepass`,
    });
    if (response?.success) {
      setData(response?.payload);
      setAllData(response?.payload);
    }
    setLoading(false);
  };

  const handleFetchDataByDate = async ({ dates }: any) => {
    setLoading(true);

    const response = await fetchDataGate({
      uri: `api/gatepass/reports/daily?start=${dates?.startDate}&end=${dates?.endDate}`,
    });
    if (response?.success) {
      setData(response?.payload);
    }
    setLoading(false);
  };

  const onRangeChange = (date: any) => {
    setStateDate(moment(new Date(date[0])).format("YYYYMMDD"));
    setEndDate(moment(new Date(date[1])).format("YYYYMMDD"));
    setDates({
      startDate: moment(new Date(date[0])).format("YYYYMMDD"),
      endDate: moment(new Date(date[1])).format("YYYYMMDD"),
      isRange: true,
    });

    handleFetchDataByDate({
      dates: {
        startDate: moment(new Date(date[0])).format("YYYYMMDD"),
        endDate: moment(new Date(date[1])).format("YYYYMMDD"),
        isRange: true,
      },
    });
  };

  const onDateChange = (date: any) => {
    setStateDate(moment(new Date(date)).format("YYYYMMDD"));
    setEndDate(moment(new Date(date)).format("YYYYMMDD"));
    setDates({
      startDate: moment(new Date(date)).format("YYYYMMDD"),
      endDate: moment(new Date(date)).format("YYYYMMDD"),
      isRange: false,
    });

    handleFetchDataByDate({
      dates: {
        startDate: moment(new Date(date)).format("YYYYMMDD"),
        endDate: moment(new Date(date)).format("YYYYMMDD"),
        isRange: false,
      },
    });
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const dt = data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        "Date In":
          item?.dates?.in === null
            ? "-"
            : moment(item?.dates?.in).format("DD/MM/YYYY"),
        "Service Advisor":
          item?.creator?.name === null ? "-" : item?.creator?.name,
        "Customer Name":
          item?.customer?.name === null ? "-" : item?.customer?.name,
        "WIP No.": item?.service?.wip === null ? "-" : item?.service?.wip,
        "Vehicle Registration":
          item?.registration === null ? "-" : item?.registration,
        Ageing:
          item?.dates?.in === null
            ? "-"
            : moment(item?.dates?.in).format("DD/MM/YYYY"),
        "Created at": moment(item?.createdAt).format("DD/MM/YYYY"),
        "Reason": item?.reason || "-",
        "Forwarded To": item?.approver?.name ? item?.approver?.name : "-",
        "Authorized By": item?.flags?.approved ? item?.approver?.name : "-",
        Status: item?.status === null ? "-" : item?.status,
      };
    });

    const raw = getExportData(dt);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const handlePageChange = (pagination: any) => {
    handleFetchData({
      pagination,
    });
  };

  const handleRefetch = () => {
    handleFetchData({
      pagination,
    });
  };

  const clearDate = () => {
    form.resetFields();
    setData(allData);
  };

  const handleSearch = (value: any) => {
    const filtered = data?.filter((item: any) => item?.service?.wip == value);
    setData(filtered);
  };

  useEffect(() => {
    const gate = pusher.subscribe(`gatepass`);
    gate.bind("created", function (data: any) {
      handleRefetch();
      message.success("Gatepass Created");
    });
    gate.bind("approved", function (data: any) {
      handleRefetch();
      message.success("Gatepass Approved");
    });

    return () => {
      pusher.unsubscribe("gatepass");
    };
  }, []);

  useEffect(() => {
    handleFetchData({
      pagination,
    });
  }, []);

  return (
    <div className="-z-50">
      <div className="flex justify-between bg-white border-b px-2 py-1">
        <div className="">
          <Form layout="inline" form={form}>
            <Form.Item name="wip">
              <Search
                placeholder="Search by WIP"
                allowClear
                className="rounded-none"
                onSearch={(value: any) => {
                  handleSearch(value);
                }}
              />
            </Form.Item>

            {showRange ? (
              <div className="flex justify-center items-center">
                <Form.Item name="ranger">
                  <RangePicker
                    allowClear={true}
                    onChange={onRangeChange}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>

                <Checkbox onChange={() => setShowRange(!showRange)}>
                  <span className="text-gray-500">Date as Range</span>
                </Checkbox>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <Form.Item name="picker">
                  <DatePicker
                    onChange={onDateChange}
                    format={"DD-MM-YYYY"}
                    placeholder="Specific date"
                    className=" border-gray-300"
                  />
                </Form.Item>
                <Checkbox onChange={() => setShowRange(!showRange)}>
                  <span className="text-gray-500">Date as Range</span>
                </Checkbox>
              </div>
            )}

            <Button className="rounded-none" onClick={() => clearDate()}>
              <RiFilterOffLine color="#6b7280" />
            </Button>
          </Form>
        </div>

        <div className="flex items-center">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  wrapperCol={{ span: 24 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" danger htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="bottom"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={20} />
              </button>
            </Tooltip>
          </Popover>
          <button
            className="border bg-white px-4 ml-2 py-1"
            onClick={() =>
              ExportToExcel(
                data?.map((item: any, index: number) => {
                  return {
                    "S/N": index + 1,
                    "Date In":
                      moment(item?.dates?.in).format("DD/MM/YYYY") || "-",
                    "Service Advisor": item?.creator?.name || "-",
                    "Customer Name": item?.customer?.name || "-",
                    "WIP No.": item?.service?.wip || "-",
                    "Vehicle Registration": item?.registration || "-",
                    Ageing:
                      item?.dates?.in === null
                        ? "-"
                        : moment(item?.dates?.in).format("DD/MM/YYYY"),
                    "Created at": moment(item?.createdAt).format("DD/MM/YYYY"),
                    "Reason": item?.reason || "-",
                    "Forwarded To": item?.approver?.name || "-",
                    "Authorized By": item?.flags?.approved || "-",
                    Status: item?.status || "-",
                  };
                }),
                "Gatepass.xlsx"
              )
            }
          >
            <RiFileExcel2Line size={20} />
          </button>
          <Button
            className="bg-white rounded-none ml-2 flex justify-center items-center"
            style={{ fontSize: 12 }}
            onClick={() => setVisible(true)}
          >
            <div className="">
              <AiOutlinePlus size={12} />
            </div>
            <div className="ml-1 pt-1">New</div>
          </Button>
        </div>
      </div>
      <div className="p-2">
        <div className="border bg-white rounded-md">
          <Table
            rowClassName={(record, index) => `hover:cursor-pointer`}
            columns={columns}
            dataSource={data}
            size={"middle"}
            onChange={handlePageChange}
            loading={isLoading}
            onRow={(r) => ({
              onClick: () => {
                navigate(`/gatepass/${r._id}`);
              },
            })}
          />
        </div>
      </div>
      <CreateGatepass
        isVisible={isVisible}
        setVisible={setVisible}
        refetch={handleRefetch}
      />
    </div>
  );
}
