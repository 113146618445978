import { useContext, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import SweetAlert2 from "react-sweetalert2";
import { AuthContext } from "../../../../contexts/AuthContext";
import deleteDataGate from "../../../../services/deleteDataGate";

export default function DeleteReason({
  isVisible,
  setVisible,
  refetch,
  selected,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const data = {};
    const uri = `api/reasons/${selected?._id}`;
    const response = await deleteDataGate({ data, uri, token: auth?._id });
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Deleted",
        text: "Reason Deleted Successfully",
        icon: "success",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Delete Reason"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          No
        </Button>,
        <Button
          key="submit"
          form="deleteReason"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Yes
        </Button>,
      ]}
    >
      <Form id="deleteReason" form={form} onFinish={onSubmit} layout="vertical">
        <div className="px-4">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-600">Delete</span> this reason ?
          </p>
        </div>
      </Form>
      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
