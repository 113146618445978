import { GATE_BASE_URL } from "../utils/urls";

const postDataGate = async ({ data, uri, token }: any) => {
  const response = await fetch(`${GATE_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export default postDataGate;
