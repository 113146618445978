import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import SweetAlert2 from "react-sweetalert2";
import { useContext, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FiLoader } from "react-icons/fi";
import { AuthContext } from "../../../contexts/AuthContext";
import postDataGate from "../../../services/postDataGate";
import fetchDataGate from "../../../services/fetchDataGate";
import { useNavigate } from "react-router";
import fetchDataArps from "../../../services/fetchDataArps";

const { Option } = Select;

export default function CreateGatepass({
  isVisible,
  setVisible,
  refetch,
}: any) {

  const [users, setUsers] = useState<any>([]);
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSearching, setSearching] = useState<boolean>(false);
  const [wip, setWip] = useState<any>(null);
  const [err, setErr] = useState<any>(null);
  const [swalProps, setSwalProps] = useState({});

  const navigate = useNavigate();

  const [reasons, setReasons] = useState([]);

  const [form]: any = Form.useForm();

  const handleSubmit = async () => {
    const {
      customer,
      driver,
      address,
      registration,
      date,
      reference,
      reason,
      userId,
    } = await form.getFieldsValue();
    const approver = users.filter((user: any) => user?._id === userId);

    const data = {
      name: customer,
      address: address,
      registration: registration,
      reference: reference,
      reason: reason,
      customer: {
        name: customer,
      },
      approver: {
        name: approver[0]?.name,
        mobile: approver[0]?.mobile,
        email: approver[0]?.email,
        user: approver[0]?._id,
        signature: approver[0]?.signature?.url,
      },
      driver: {
        name: driver,
      },
      service: {
        wip: wip,
      },
      dates: {
        in: date,
      },
      creator: {
        name: auth?.name,
        user: auth?._id,
      },
    };

    const uri = "api/gatepass";
    const response = await postDataGate({ data, uri, token: auth?._id });
    if (response?.success) {
      setSwalProps({
        show: true,
        title: "Created",
        text: "Gatepass Created Successfully",
        icon: "success",
      });
      await refetch();
      setVisible(false);
      navigate(`/gatepass/${response?.payload?._id}`);
    } else {
      setSwalProps({
        show: true,
        title: "Sorry",
        text: "Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
    }
  };

  const handleSearchWIP = async () => {
    if (!wip) {
      setErr(true);
      return;
    }
    form.resetFields();
    setSearching(true);
    const response = await fetchDataArps({ uri: `gatepass/${wip}` });
    if (response.payload.length > 0) {
      const data = response.payload[0];

      let d: any = null;
      if (data?.date_in !== null) {
        d = moment(data?.date_in.toString(), "YYYYMMDD").format("MM/DD/YYYY");
        form.setFieldsValue({
          customer: data?.customer,
          registration: data?.regno,
          date: moment(new Date(d)),
          address: `${data?.address} ${data?.address2}`,
          wip: wip,
        });
      } else {
        form.setFieldsValue({
          customer: data?.customer,
          registration: data?.regno,
          address: `${data?.address} ${data?.address2}`,
          wip: wip,
        });
      }
    } else {
      setSwalProps({
        show: true,
        title: `Sory`,
        text: `No Data Found for WIP ${wip}`,
        icon: "error",
      });
      setVisible(false);
      setErr(false);
      setSwalProps({});
    }
    setSearching(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setSearching(false);
  };

  const handleFetchUsers = async () => {
    const response = await fetchDataGate({ uri: "api/users" });

    if (response?.success) {
      const filtered = response?.payload?.filter(
        (item: any) => item?.canApprove
      );
      setUsers(filtered);
    }
  };

  const reasonOptions = reasons?.map((level: any, index: number) => (
    <Option value={level?.reason} key={index}>
      {level?.reason}
    </Option>
  ));

  const userOptions = users.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.name}
    </Option>
  ));

  const handleFetchReasons = async () => {
    const response = await fetchDataGate({ uri: "api/reasons" });
    if (response?.success) {
      setReasons(response?.payload);
    }
  };

  useEffect(() => {
    handleFetchReasons();
    handleFetchUsers();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Create Gate Pass"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createUser"
          htmlType="submit"
          type="primary"
          onSubmit={handleSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <div className="flex border-b pb-2 mb-2">
        <div className="">
          <input
            type="number"
            placeholder="Enter WIP No."
            className={`border py-1 px-2 rounded-sm flex-1 ${
              err ? "border-red-500" : ""
            }`}
            value={wip}
            onChange={(e) => {
              setErr(false);
              setWip(e.target.value);
            }}
          />
        </div>
        <button
          className="bg-toyota p-2 ml-2"
          onClick={() => handleSearchWIP()}
        >
          {isSearching ? (
            <FiLoader color="#FFFFFF" />
          ) : (
            <BsSearch color="#FFFFFF" />
          )}
        </button>
      </div>
      <Form
        id="createUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="customer"
              label="Customer Name"
              rules={[
                {
                  required: true,
                  message: "Please enter customer's name",
                },
              ]}
            >
              <Input type="text" placeholder="Customer Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="driver"
              label="Driver Name"
              rules={[
                {
                  required: true,
                  message: "Please enter driver's name",
                },
              ]}
            >
              <Input type="text" placeholder="Driver Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Customer Address"
              rules={[
                {
                  required: true,
                  message: "Please enter customer's address",
                },
              ]}
            >
              <Input type="text" placeholder="Customer Address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="registration"
              label="Vehicle Registration No."
              rules={[
                {
                  required: true,
                  message: "Please enter vehicle Registration",
                },
              ]}
            >
              <Input type="text" placeholder="Vehicle Registration" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="date"
              label="Date In"
              rules={[
                {
                  required: true,
                  message: "Please pick date in",
                },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                className="w-full"
                placeholder="Date In"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="wip"
              label="WIP Number"
              rules={[
                {
                  required: true,
                  message: "Please enter WIP Number",
                },
              ]}
            >
              <Input type="text" placeholder="WIP Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="reason"
              label="Select Reason"
              rules={[
                {
                  required: true,
                  message: "Please select reason",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select reason"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {reasonOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="userId"
              label={"Approver"}
              rules={[
                {
                  required: true,
                  message: "Please select approver",
                },
              ]}
            >
              <Select placeholder="Approver">{userOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="reference"
              label="Reference"
              rules={[
                {
                  required: false,
                  message: "Please enter reference",
                },
              ]}
            >
              <Input type="text" placeholder="Reference" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
