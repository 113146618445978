import logo from "../../../assets/images/logo-red.png";
export default function DocumentTop() {
  return (
    <div className="flex flex-col justify-center items-center mb-8">
      <div className="flex items-center">
        <img src={logo} width={60} />
        <span className="font-bold text-2xl">Toyota Tanzania Limited</span>
      </div>
      <div className="">No. 5 Nyerere Road, Dar es Salaam, Tanzania</div>
      <div className="">Tel: (255) 22 - 2866815-8, 2866353-8</div>
      <div className="">Fax: 22-2866814, 2861293</div>
    </div>
  );
}
