import { ARPS_BASE_URL } from "../utils/urls";

const fetchDataArps = async ({ uri }: any) => {
  const response = await fetch(`${ARPS_BASE_URL}/${uri}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  return result;
};

export default fetchDataArps;
