import { DatePicker, Checkbox, Button, Form } from "antd";
import moment from "moment";
import { useState } from "react";
import { BsCalendarCheck } from "react-icons/bs";
import { RiFilterOffLine } from "react-icons/ri";

const { RangePicker } = DatePicker;

export default function Header({ stateHandler }: any) {
  const [showRange, setShowRange] = useState(false);
  const start = moment(new Date()).format("YYYYMMDD");
  const end = moment(new Date()).format("YYYYMMDD");
  const [startDate, setStateDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [selected, setSelected] = useState<any>(null);
  const [form] = Form.useForm();

  const onRangeChange = (date: any) => {
    setStateDate(moment(new Date(date[0])).format("YYYYMMDD"));
    setEndDate(moment(new Date(date[1])).format("YYYYMMDD"));
    stateHandler({
      startDate: moment(new Date(date[0])).format("YYYYMMDD"),
      endDate: moment(new Date(date[1])).format("YYYYMMDD"),
      isRange: true,
    });
  };

  const periods = [
    { id: 1, name: "Weekly" },
    { id: 2, name: "Monthly" },
    { id: 3, name: "Yearly" },
  ];

  const onSelectPeriod = (period: any) => {
    if (period?.name === "Weekly") {
      const start = moment().startOf("isoWeek").format("YYYYMMDD");
      const end = moment().endOf("isoWeek").format("YYYYMMDD");
      setStateDate(start);
      setEndDate(end);
      stateHandler({
        startDate: moment().startOf("isoWeek").format("YYYYMMDD"),
        endDate: moment().endOf("isoWeek").format("YYYYMMDD"),
        isRange: true,
      });
    }
    if (period?.name === "Monthly") {
      const start = moment().startOf("month").format("YYYYMMDD");
      const end = moment().endOf("month").format("YYYYMMDD");
      setStateDate(start);
      setEndDate(end);
      stateHandler({
        startDate: moment().startOf("month").format("YYYYMMDD"),
        endDate: moment().endOf("month").format("YYYYMMDD"),
        isRange: true,
      });
    }
    if (period?.name === "Yearly") {
      const start = moment().startOf("year").format("YYYYMMDD");
      const end = moment().endOf("year").format("YYYYMMDD");
      setStateDate(start);
      setEndDate(end);
      stateHandler({
        startDate: moment().startOf("year").format("YYYYMMDD"),
        endDate: moment().endOf("year").format("YYYYMMDD"),
        isRange: true,
      });
    }
  };

  const onDateChange = (date: any) => {
    setStateDate(moment(new Date(date)).format("YYYYMMDD"));
    setEndDate(moment(new Date(date)).format("YYYYMMDD"));
    stateHandler({
      startDate: moment(new Date(date)).format("YYYYMMDD"),
      endDate: moment(new Date(date)).format("YYYYMMDD"),
      isRange: false,
    });
  };

  const clearDate = () => {
    const period = { id: 1, name: "Weekly" };
    form.resetFields();
    const start = moment().startOf("isoWeek").format("YYYYMMDD");
    const end = moment().endOf("isoWeek").format("YYYYMMDD");
    setStateDate(start);
    setEndDate(end);
    setSelected(period);
    stateHandler({
      startDate: moment(new Date()).format("YYYYMMDD"),
      endDate: moment(new Date()).format("YYYYMMDD"),
      isRange: false,
    });
    setSelected(null);
  };

  return (
    <div className="flex justify-between items-center border-b  border-gray-300 py-2 mx-4">
      <div className="flex">
        <Form layout="inline" form={form}>
          {showRange ? (
            <div className="flex justify-center items-center">
              <Form.Item name="ranger">
                <RangePicker
                  allowClear={true}
                  onChange={onRangeChange}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>

              <Checkbox onChange={() => setShowRange(!showRange)}>
                <span className="text-gray-500">Date as Range</span>
              </Checkbox>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Form.Item name="picker">
                <DatePicker
                  onChange={onDateChange}
                  format={"DD-MM-YYYY"}
                  placeholder="Specific date"
                />
              </Form.Item>
              <Checkbox onChange={() => setShowRange(!showRange)}>
                <span className="text-gray-500">Date as Range</span>
              </Checkbox>
            </div>
          )}
          <Button className="" onClick={() => clearDate()}>
            <RiFilterOffLine color="#6b7280" />
          </Button>
        </Form>
      </div>
      <div className="">
        <div className="flex flex-row">
          {periods.map((period: any, index: number) => (
            <button
              className={`flex justify-center items-center border border-gray-300 py-2 px-4 ml-2 ${
                selected?.id === period.id ? `bg-red-100` : ``
              }`}
              key={index}
              onClick={() => {
                setSelected(period);
                onSelectPeriod(period);
              }}
            >
              <span>
                <BsCalendarCheck />
              </span>
              <span className="ml-2">{period.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
