import { Button, Modal, Form, Row, Col, Select, Input, Checkbox } from "antd";
import { useContext, useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import SweetAlert2 from "react-sweetalert2";
import { AuthContext } from "../../../../contexts/AuthContext";
import fetchDataTrace from "../../../../services/fetchDataTrace";
import postDataGate from "../../../../services/postDataGate";
import getBase64 from "../../../../utils/getBase64";

const { Option } = Select;

export default function CreateUser({ isVisible, setVisible, refetch }: any) {
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [swalProps, setSwalProps] = useState({});
  const [users, setUsers] = useState<any>([]);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const roles = [
    { id: 1, name: "SA", desc: "Service Advisor" },
    { id: 2, name: "HBO", desc: "Head Branch Operation" },
    { id: 3, name: "LM", desc: "Line Manager" },
    { id: 4, name: "AP", desc: "Authorized Personel" },
    { id: 5, name: "FOM", desc: "Front Office Manager" },
    { id: 6, name: "AD", desc: "Super Admin" },
  ];

  const handleFetchUsers = async () => {
    const uri = `users`;
    const response = await fetchDataTrace({ uri });
    if (response.success) {
      setUsers(response?.payload);
    }
  };

  const userOptions = users.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.firstname + " " + user?.lastname}
    </Option>
  ));

  const roleOptions = roles?.map((level: any, index: number) => (
    <Option value={level?.name} key={index}>
      {level?.desc}
    </Option>
  ));

  const onSubmit = async () => {
    setLoading(true);

    const { userId, role } = await form.getFieldsValue();
    const user = users?.filter((user: any) => user?._id == userId);

    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const data = {
      name: `${user[0]?.firstname} ${user[0]?.lastname}`,
      email: user[0]?.email,
      mobile: user[0]?.mobile,
      role,
      canApprove,
      signature: base64,
    };
    const uri = "api/users";
    const response = await postDataGate({ data, uri, token: auth?._id });
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Created",
        text: "Gatepass Created Successfully",
        icon: "success",
      });
      await refetch();
      setVisible(false);
      await refetch();
      setLoading(false);
    } else {
      setSwalProps({
        show: true,
        title: "Sorry",
        text: "Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  useEffect(() => {
    handleFetchUsers();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="New User"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="userId"
              label="Select User"
              rules={[
                {
                  required: true,
                  message: "Please select user",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select user"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="role"
              label={"Designation"}
              rules={[
                {
                  required: true,
                  message: "Please select level",
                },
              ]}
            >
              <Select placeholder="Designation">{roleOptions}</Select>
            </Form.Item>
          </Col>
        </Row>

        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Checkbox
            onChange={(e: any) => {
              setCanApprove(e.target.checked);
            }}
            defaultChecked={canApprove}
          >
            Approver
          </Checkbox>
        </Col>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border mt-2">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Image Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Signature ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Signature"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>

      <SweetAlert2
        {...swalProps}
        confirmButtonColor="#E50000"
        customClass={{ confirmButton: "#e50000" }}
      />
    </Modal>
  );
}
